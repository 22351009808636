<template>
  <!-- 课程赋值弹层 -->
  <el-dialog
    title="复制课程"
    :visible.sync="dialogVisibleSource"
    width="50%"
    top="2%"
    :center="true"
    :before-close="doClose"
  >
    <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
      <el-form-item label="课程名称" prop="sourcename">
        <el-input v-model="Form.sourcename" type="text" size="small" placeholder="请输入课程名称" maxlength="50"
  show-word-limit />
      </el-form-item>
      <el-form-item label="培训类型" prop="Trainingtype" class="form-item">
        <tree
          ref="tree"
          @eventBtn="childBack"
          :ruleForm="Form"
          @clearParams="clearParams"
          :modal="false"
          type
          typeStu
        />
      </el-form-item>
      <el-form-item label="课程图片" prop="thumbnail">
        <el-upload
          v-model="Form.thumbnail"
          :on-change="handleAvatarSuccess2"
          :before-upload="$beforeAvatarUpload"
          :http-request="$requestMine"
          :show-file-list="false"
          class="img-el-upload"
          action
          accept="image/png, image/gif, image/jpg, image/jpeg"
        >
          <el-image
            :src="Form.thumbnail || require('@/assets/develop.png')"
            fit="contain"
            class="imgCenter"
          ></el-image>
        </el-upload>
      </el-form-item>
      <el-form-item label="课程详情" prop="context" class="form-item">
        <div ref="editor" style="width:100%;position: relative;z-index: 2;" />
        <span v-show="showtitle" class="showtitle">请输入课程详情</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
      <el-button class="bgc-bv" @click="addOptions">确定</el-button>
      <el-button @click="docancle('ruleForm')">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import List from "@/mixins/List";
import E from "wangeditor";

import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
let editor = {};
export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
    tree
  },
  mixins: [UploadPic, List],
  data() {
    return {
      dialogVisibleSource: false,
      showtitle: false,
      paperList: [],
      courseId: "",
      ruleForm: {},
      Form: {
        sourcename: "",
        Trainingtype: "",
        thumbnail: "",
        context: "",
        fileKey: ""
      },
      rules: {
        sourcename: [
          { required: true, message: "请输入课程名称", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "changhe" }
        ],
        thumbnail: [
          { required: true, message: "请上传课程图片", trigger: "change" }
        ]
      },
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      params: {}
    };
  },
  created() {},
  mounted() {
    // console.log(document.getElementById("list-box").offsetHeight);
    // const height = document.getElementById('list-box').offsetHeight;
  },
  computed: {
    ...mapGetters({
      getTraintype: "getTraintype"
    })
  },
  methods: {
    init() {},
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    showPopUp(courseId) {
      this.dialogVisibleSource = true;
      this.courseId = courseId;
      this.getsingle(courseId);
      // this.showtitle = false;
      this.$nextTick(function() {
        this.editorInit();
        editor.txt.html("");
        // this.getsingle(questionId);
      });
    },
    // getList() {
    //   this.dialogVisible = false;
    //   if (this.getTraintype.length) {
    //     this.datatreeList = this.getTraintype;
    //     return;
    //   }
    //   this.$post("/sys/category/train-type/tree").then(ret => {
    //     const retdata = ret.data || [];
    //     if (retdata.length) {
    //       this.datatreeList = retdata;
    //       this.$store.dispatch("handleTraintype", retdata);
    //     }
    //   });
    // },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.onchange = html => {
        this.ruleForm.content = html;
        this.showtitle = false;
      };
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.create();
    },
    //上传图片
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.Form.thumbnail = result.data.fileURL;
          this.Form.fileKey = result.data.fileKey;
          console.log(result);
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败"
            });
          }, 300);
        });
    },
    // 获取单个数据
    getsingle(courseId) {
      this.$post("/biz/course/display", { courseId })
        .then(ret => {
          const form = ret.data || {};
          (this.Form = {
            sourcename: form.courseName,
            // Trainingtypecopy: form.trainTypeId,
            thumbnail: form.logoURL,
            fileKey: form.logo
          }),
          editor.txt.html(form.context);
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (form.trainTypeId) {
            this.params.trainFormId = form.trainTypeId;
            name1 = form.trainTypeNamePath;
          }
          if (form.postId) {
            this.params.postFormId = form.postId;
            name2 = "/" + form.postName;
          }
          if (form.industryId) {
            this.params.industryFormId = form.industryId;
            name3 = "/" + form.industryNamePath;
          }

          if (form.occupationId) {
            this.params.occFormId = form.occupationId;
            name4 = "/" + form.occupationNamePath;
          }
          if (form.trainLevelId) {
            this.params.levelFormId = form.trainLevelId;
            name5 = "/" + form.trainLevelName;
          }
          let name = (name1 + name2 + name3 + name4 + name5).replace(/,/g, "-");
          this.Form.Trainingtype = name;
        })
        .catch(err => {
          console.log(err);
        });
    },

    addOptions() {
      this.$refs.Form.validate(valid => {
        if (valid) {
          if (editor.txt.text() == "") {
            this.showtitle = true;
            return false;
          } else {
            this.showtitle = false;
            let parmar = {
              courseId: this.courseId,
              courseName: this.Form.sourcename,
              logo: this.Form.fileKey,
              trainTypeId: this.Form.Trainingtypecopy,
              context: editor.txt.html()
            };
            if (this.params.trainFormId) {
              parmar.trainTypeId = this.params.trainFormId;
            }
            if (this.params.levelFormId) {
              parmar.trainLevelId = this.params.levelFormId;
            }
            if (this.params.occFormId) {
              parmar.occupationId = this.params.occFormId;
            }
            if (this.params.postFormId) {
              parmar.postId = this.params.postFormId;
            }
            if (this.params.industryFormId) {
              parmar.industryId = this.params.industryFormId;
            }
            this.$post("/biz/course/courseCopy", parmar)
              .then(ret => {
                if (ret.status == 0) {
                  this.dialogVisibleSource = false;
                  editor.txt.clear();
                  this.$emit("eventbtn");
                }
              })
              .catch(err => {
                console.log(err);
              });
          }
        }
      });
    },
    docancle() {
      this.dialogVisibleSource = false;
      this.empty();
    },
    empty() {
      (this.Form = {
        sourcename: "",
        Trainingtypecopy: "",
        thumbnail: "",
        context: "",
        fileKey: ""
      }),
        this.$refs["Form"].resetFields();
      this.ruleForm = {};
      editor.txt.clear();
    },
    closeDialog() {
      this.dialogVisibleSource = false;
      this.empty();
    }
  }
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>
